import css from './Location.module.scss'
import global from "../../../styles/Global.module.scss"
import Link from "next/link"
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util"

const Location = ({title, name, text, url, image, auto, foot, by_auto_text, by_yorself_text}) => {

    //animation
    const ref = useRef(null)
    const {isVisible: inView} = useVisible(ref)
    //

    const [visible, setVisible] = useState(false)
    const img_data = SelectImage("/files/images/main/main-map.png", image)

    useEffect(() => {
        if(!visible && inView) {
            setVisible(true)
        }
    }, [inView])

    return (
        <section className={` ${global.section} ${css.location}`} ref={ref}>
            <h2 className='main-page__title main-page__location' dangerouslySetInnerHTML={{__html:title}}></h2>
            <div className={inView ? `${css.animation} ${css.map}` : `${css.map}`}>
                {visible && <img src={img_data} alt={""} loading={"lazy"}/>}
            </div>
            <div className={inView ? `${css.animation} ${css.text}` :`${css.text}`}>
                <div dangerouslySetInnerHTML={{__html:text}}></div>
                <p className={css.title}>{by_auto_text}</p>
                <ul className={css.list}>
                    {auto.map(item => (
                        <li key={item.id}><span>{item.number}</span>{item.text}</li>
                    ))}
                </ul>
                <p className={css.title}>{by_yorself_text}</p>
                <ul className={css.list}>
                    {foot.map(item => (
                        <li key={item.id}><span>{item.number}</span>{item.text}</li>
                    ))}
                </ul>
                <Link href={`/location`}><a className={`${css.button} btn`}>{name}</a></Link>
            </div>
        </section>
    )
}

export default Location